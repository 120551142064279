/*
This is where you write custom SASS
*/

::placeholder {
    font-family: 'brother-1816', sans-serif;
}

.form-control {
    font-family: 'brother-1816', sans-serif;
    font-size: 12px;
    font-weight: 500;
    text-transform: uppercase;
    letter-spacing: 0.15em;
}

.text-initial {
    text-transform: initial;
}

.backdrop-blur {
  background-color: rgba(233, 233, 233, 0.9);
}

@supports ((-webkit-backdrop-filter: blur(50px)) or (backdrop-filter: blur(50px))) {
  .backdrop-blur {
    background: rgba(245,245,245,0.75);
    -webkit-backdrop-filter: blur(50px);
    backdrop-filter: blur(50px);
  }
}

//interior custom scss
body.interior {
    section[id*="interiorBanner"] {
        height: 615px;
        min-height: 400px;
        @media screen and (max-width: 1600px) {
            & {
                height: 40vw;
            }
        }
    }
    &.page-type-3 {
        .card {
            padding: 60px 55px;
            background: #5A9DDC;
            p.h3 {
                text-transform: uppercase;
                color:  $gray-800
            }
            [id*="HeaderInfoDiv"] {
                font-size: 18px;
                font-weight: 300;
                color: $gray-800;
                line-height: 30px;
            
            }
            label {
                display: none;
            }
        }
    }
    &.page-type-26 {
        .page-content .container {
            max-width: 960px;
        }
    }
}
